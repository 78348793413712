import React, {useState} from "react"
import classNames from "classnames"
import {Layout} from "antd"
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import {compose} from "redux"
import styled, {ThemeProps, withTheme} from "styled-components"

// Models
import ThemeInterface from "../../theme/ThemeInterface"

// HOCs
import withSidebarToggle, {WithSidebarToggleProps} from "../../modules/common/hoc/withSidebarToggle"

type SidebarProps = {
    className?: string
    sidebarClassName?: string
    children: Array<JSX.Element> | JSX.Element
} & ThemeProps<ThemeInterface> & WithSidebarToggleProps

/**
 * @param props
 * @returns
 */
const Sidebar = (props: SidebarProps) => {
    const {className, sidebarClassName, children, theme, toggleSidebar, sidebarClosed} = props;
    const [isBreakpoint, setIsBreakpoint] = useState(false)

    return (
        <Layout.Sider
            className={classNames(className, sidebarClassName)}
            theme="light"
            width={theme.constants.sidebarWidth}
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={sidebarClosed}
            onBreakpoint={broken => setIsBreakpoint(broken)}
            trigger={null}
        >
            {children}
            {isBreakpoint && (
                <div className="trigger" onClick={toggleSidebar}>
                    {
                        sidebarClosed ?
                        <MenuUnfoldOutlined className="trigger-icon" /> :
                        <MenuFoldOutlined className="trigger-icon" />
                    }
                </div>
            )}
        </Layout.Sider>
    )
}


export default compose(
    withTheme,
    withSidebarToggle,
)(styled(Sidebar)`
  height: calc(100vh - ${props => props.theme.constants.headerHeight}px);
  position: relative;
  
  .trigger {
      position: absolute;
      top: 75px;
      right: -50px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({theme}) => theme.color.white};
      border: 1px solid ${({theme}) => theme.color.white};
      border-left: 0;
      z-index: 9;
      cursor: pointer;
  }
  
  .trigger-icon {
      font-size: 30px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: ${({theme}) => theme.color.blue};
  }
  
  @media print {
    display: none;
  }
`)