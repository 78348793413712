import React from "react"
import {Avatar} from "antd"
import styled from "styled-components"

// HOCs
import withUtils, {WithUtilsProps} from "../../hoc/withUtils"

export type UserAvatarProps = {
    size?: number
    fontSize?: number
    firstname: string
    lastname: string | null
    className: string
} & WithUtilsProps

/**
 * @param props
 * @returns {JSX.Element}
 */
function UserAvatar(props: UserAvatarProps) {
    const {utils, className, firstname, lastname, size = 40, fontSize = 16} = props;

    return (
        <Avatar size={size} className={className} style={{fontSize}}>
            {utils.getUserFirstLetters(firstname, lastname)}
        </Avatar>
    )
}

export default withUtils(
    styled(UserAvatar)`
      background-color: #e6e6eb;
      color: ${props => props.theme.color.blue};
      font-weight: ${props => props.theme.fontWeight.semiBold};
      
      & > span {
        opacity: 1 !important; // Fix for Ant "Avatar" behaviour in prod.
      }
    `,
)
